<template>
  <CRow>
    <CCol
      col="3"
      v-for="(data, taskId) in tasks"
      :key="taskId"
      class="task-wrapper"
    >
      <CWidgetProgressIcon
        :header="`${$t('Task')} ${taskId}`"
        v-if="!data"
        :value="0"
      >
        <CButton
          color="primary"
          @click="startTask(taskId)"
          v-if="!data"
          class="mt-1"
        >
          {{ $t("Start") }}
        </CButton>
      </CWidgetProgressIcon>
      <CWidgetProgress
        :header="`${$t('Task')} ${taskId}`"
        :value="data.progress"
        :text="data.text"
        :footer="data.state && `${$t('State')}: ${data.state}`"
        v-else-if="!data.ready || data.exception"
        class="task-progress"
      >
        <div v-if="data.exception">
          {{ data.exception }}
        </div>
      </CWidgetProgress>
      <CWidgetBrand
        color="primary"
        :left-header="`${data.result.casual_sum}`"
        :left-footer="$t('Casual sum')"
        :right-header="`${data.result.rows}`"
        :right-footer="$t('Total rows')"
        v-else
      />
    </CCol>
  </CRow>
</template>

<script>
import { SampleService } from "@/services/sample";
import Task from "@/services/task";

export default {
  name: "Tasks",
  data() {
    return {
      tasks: {
        1: null,
        2: null,
        3: null,
        4: null,
      },
    };
  },
  unmounted() {
    Object.values(this.tasks).forEach((taskData) => taskData?.task?.abort());
  },
  methods: {
    handleProgress(data, taskId) {
      this.tasks[taskId] = {
        ...data,
        text: `${this.$t("Running")} ${data.progress}%`,
      };
    },
    handleResult(result, taskId) {
      this.tasks[taskId] = { ready: true, text: this.$t("Result"), result };
    },
    handleError(result, taskId) {
      this.tasks[taskId] = { ...result, text: this.$t("Failure") };
    },
    async startTask(taskId) {
      const taskData = await SampleService.startSampleTask();
      const newTask = new Task(taskData.task_id);
      newTask.on("progress", (data) => this.handleProgress(data, taskId));
      newTask.on("result", (data) => this.handleResult(data, taskId));
      newTask.on("error", (data) => this.handleError(data, taskId));
    },
  },
};
</script>

<style scoped lang="scss">
.task-wrapper {
  .card {
    min-height: 193px;
  }

  .c-chart-brand {
    max-height: 100px;
  }

  .task-progress .h4 {
    margin-top: 3rem !important;
  }
}
</style>
