import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const TASKS_ENDPOINTS = {
  task: (taskId) => `${BASE_URL}api/tasks/${taskId}`,
};

export default class Task {
  timeout;
  eventHandlers = {};
  aborted = false;

  constructor(taskId) {
    this.taskId = taskId;
    this.checkTaskStatus();
  }

  on(eventName, callback) {
    this.eventHandlers[eventName] = callback;
  }

  stop() {
    clearTimeout(this.timeout);
    this.aborted = true;
  }

  abort = async () => {
    this.stop();
    return await request({
      url: TASKS_ENDPOINTS.task(this.taskId),
      method: "DELETE",
    });
  };

  checkTaskStatus = async () => {
    if (this.aborted) {
      return;
    }
    let response;
    try {
      response = await request({
        url: TASKS_ENDPOINTS.task(this.taskId),
      });
    } catch (e) {
      return this.eventHandlers?.error({ exception: e });
    }

    if (this.aborted) {
      return;
    }
    const { data } = response;
    if (data.status === "SUCCESS") {
      this.eventHandlers.result?.(data.result);
    } else if (data.status === "FAILURE" || data.status === "REVOKED") {
      this.eventHandlers.error?.(data);
    } else {
      this.eventHandlers.progress?.({
        ...data,
        taskId: this.taskId,
        progress: data.progress || 0,
        task: this,
      });
      this.timeout = setTimeout(this.checkTaskStatus, 250);
    }
  };
}
