import { BASE_URL } from '@/constants/config';
import { request } from '@/utils/request';

const sampleUrl = `${BASE_URL}api/sample/`
export const SAMPLE_ENDPOINTS = {
  bikesChart: (start, end) => `${sampleUrl}plot/rented-bikes/${start}/${end}/`,
  registeredCasualChart: `${sampleUrl}plot/registered-casual/`,
  lazyTable: `${sampleUrl}lazytable/`,
  sampleTask: `${BASE_URL}api/tasks/sample`,
};

class Sample {
  async startSampleTask() {
    const { data } = await request({
      url: SAMPLE_ENDPOINTS.sampleTask,
      method: 'POST',
    });
    return data;
  }

  async fetchBikesChart(start, end) {
    const { data } = await request({
      url: SAMPLE_ENDPOINTS.bikesChart(start, end),
    });
    return data;
  }

  async fetchRegisteredCasualChart() {
    const { data } = await request({
      url: SAMPLE_ENDPOINTS.registeredCasualChart,
    });
    return data;
  }

  async fetchLazyTable() {
    const { data } = await request({
      url: SAMPLE_ENDPOINTS.lazyTable,
    });
    return data;
  }
}

export const SampleService = new Sample();
